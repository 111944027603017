import { createContext, useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';

import { useRequest } from 'ahooks';
import isUndefined from 'lodash/isUndefined';

import { admin } from '@saturn/api';
import { Icon, Spin, notification } from '@saturn/uikit';

import { addTokenToRequest } from '../../services/api';
import { AuthStore } from './models';

export const UserContext = createContext<AuthStore>({
  isAuthEnabled: true,
  isAuthorized: false,
  user: undefined,
  refreshUser: () => null,
  logout: () => null,
});

export const UserContextProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const [isAuthEnabled, setIsAuthEnabled] = useState<boolean>(true);
  const [inited, setInited] = useState<boolean>(false);

  const {
    run: getUser,
    mutate: setUser,
    data: user,
    loading: userLoading,
  } = useRequest(admin.getCurrentUser, {
    manual: true,
    onSuccess: userData => {
      if (!userData) {
        setIsAuthEnabled(false);
      }
      setInited(true);
    },
    onError: () => {
      setInited(true);
    },
  });

  const { run: expireTokens } = useRequest(admin.logout, {
    manual: true,
    onSuccess: () => {
      setUser();
      notification.success({
        message: 'You have been successfully logged out',
      });
      localStorage.removeItem('accessToken');
      addTokenToRequest();
    },
  });

  useEffect(() => {
    addTokenToRequest();
    setTimeout(() => {
      getUser();
    }, 0);
  }, []);

  return (
    <UserContext.Provider
      value={{ isAuthEnabled, isAuthorized: !isUndefined(user), user, refreshUser: getUser, logout: expireTokens }}
    >
      <Spin spinning={userLoading} indicator={<Icon name="sync-outline" />}>
        {inited && children}
      </Spin>
    </UserContext.Provider>
  );
};
