import { useContext } from 'react';

import { Form } from '@saturn/uikit';

import ImportPageContext from '../contexts/ImportPageContext';

export default function useImportedInlineBody(name: (string | number)[]) {
  const importContext = useContext(ImportPageContext);
  const form = Form.useFormInstance();
  const fieldId = form.getFieldValue(name)?.id;
  return importContext?.landingMediaFiles.find(f => f.id === fieldId)?.inlineBody;
}
