import { useState } from 'react';

import { Spin } from '@saturn/uikit';

import DoYouWantToExportContentModal from './DoYouWantToExportContentModal';
import DoYouWantImportContentModal from './DoYouWantToImportContentModal';
import ImportPageContentModal from './ImportPageContentModal';
import { ReactComponent as ExportIcon } from './export.svg';
import { ReactComponent as ImportIcon } from './import.svg';

import styles from './ExportImport.module.scss';

type ExportImportProps = {
  onExportPage: () => void;
  onImportPage: (form: FormData) => void;
  onSaveChangesAndExport: () => void;
  hasUnsavedChanges: boolean;
  importLoading: boolean;
  exportLoading: boolean;
};
export default function ExportImport({
  onExportPage,
  onImportPage,
  hasUnsavedChanges,
  onSaveChangesAndExport,
  importLoading,
  exportLoading,
}: ExportImportProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [doYouWantToExportModalOpen, setDoYouWantToExportModalOpen] = useState(false);
  const [doYouWantToImportModalOpen, setDoYouWantToImportModalOpen] = useState(false);

  const exportPage = () => {
    if (hasUnsavedChanges) {
      setDoYouWantToExportModalOpen(true);
    } else {
      onExportPage();
    }
  };

  const importPage = () => {
    if (hasUnsavedChanges) {
      setDoYouWantToImportModalOpen(true);
    } else {
      setIsModalOpen(true);
    }
  };

  return (
    <>
      <div className={styles.buttonsContainer}>
        <Spin spinning={exportLoading}>
          <button className={styles.button} onClick={exportPage}>
            <ExportIcon />
            Export content
          </button>
        </Spin>
        <Spin spinning={importLoading}>
          <button className={styles.button} onClick={importPage}>
            <ImportIcon />
            Import Content
          </button>
        </Spin>
      </div>
      {doYouWantToExportModalOpen && (
        <DoYouWantToExportContentModal
          isOpen={doYouWantToExportModalOpen}
          onClose={() => setDoYouWantToExportModalOpen(false)}
          onSaveAndExport={() => {
            setDoYouWantToExportModalOpen(false);
            onSaveChangesAndExport();
          }}
        />
      )}
      {doYouWantToImportModalOpen && (
        <DoYouWantImportContentModal
          isOpen={doYouWantToImportModalOpen}
          onClose={() => setDoYouWantToImportModalOpen(false)}
          onImport={() => {
            setDoYouWantToImportModalOpen(false);
            setIsModalOpen(true);
          }}
        />
      )}
      {isModalOpen && (
        <ImportPageContentModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onImportPage={onImportPage}
        />
      )}
    </>
  );
}
