import { LandingMediaFiles, admin } from '@saturn/api';

// any is used because we would need to write huge union with all the nesting for out landing this type can come up with more then  200 lines and it will be hard to maintain
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
type IndexedValues = any;

type PathToId = (string | number)[];
const findPath = (obj: IndexedValues, targetId: string, currentPath: PathToId = []): PathToId => {
  if (obj && typeof obj === 'object') {
    for (const key in obj) {
      const typeKey = key;
      if (obj[typeKey] && typeof obj[typeKey] === 'object') {
        // Check if this object has our `id` matching the targetId
        if ('id' in obj[typeKey] && obj[typeKey].id === targetId) {
          return [...currentPath, typeKey];
        }
        // Recursively search deeper
        const result = findPath(obj[typeKey], targetId, [...currentPath, typeKey]);
        if (result) {
          return result;
        }
      }
    }
  }
  return [];
};

const uploadImportedImages = async (
  importImages: LandingMediaFiles[],
  indexedValues: IndexedValues,
  onSuccess: (results: ({ path: PathToId } & LandingMediaFiles)[]) => void,
) => {
  const imagesWithPath = importImages
    ?.map(image => {
      const targetId = image.id;
      const path = findPath(indexedValues, targetId);
      return { ...image, path };
    })
    ?.filter(image => !!image.path);
  if (imagesWithPath?.length) {
    const results = await Promise.all(
      imagesWithPath.map(async imageWithPath => {
        const imageFormData = new FormData();
        const file = await fetch(imageWithPath.inlineBody)
          .then(res => res.blob())
          .then(blob => {
            // Extract MIME type from the Base64 string (if available)
            const mimeTypeMatch = imageWithPath.inlineBody.match(/^data:(.*?);base64,/);
            const mimeType = mimeTypeMatch ? mimeTypeMatch[1] : 'image/png';
            return new File([blob], 'File name', { type: mimeType });
          });
        imageFormData.append('file', file);
        const response = await admin.uploadFile({ form: imageFormData, fileName: imageWithPath.id });
        return { ...imageWithPath, ...response };
      }),
    );
    if (results.length) {
      onSuccess(results);
    }
  }
};

export default uploadImportedImages;
